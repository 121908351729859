<template>
  <div class="sidebar">
    <ContextMenu :display="showContextMenu" ref="menuUsers">
      <div v-for="(user, index) in usersList" @click.stop="filterUserMessages(user)" :key="index" :style="isUserSelected(user)">{{user}}</div>
    </ContextMenu>
    <div class="sidebar__header">
      <div class="sidebar__header_left">
        <v-avatar size="30"><v-icon x-large>mdi-phone</v-icon></v-avatar>
        <div class="sidebar__user">
          <div class="sidebar__user_description" :title="phoneList.current.description">{{ phoneList.current.description }}</div>
          <div>{{ phoneList.current.number }}</div>
        </div>
      </div>
      <div class="sidebar__header_center">
        <v-btn-toggle
            v-model="text"
        >
          <v-btn value="chats" title="Conversaciones">
            <v-icon>mdi-wechat</v-icon>
          </v-btn>
          <v-btn value="agenda" title="Agenda">
            <v-icon>mdi-account-box-outline</v-icon>
          </v-btn>
          <v-btn value="seguimiento" title="Favoritos">
            <v-icon color="#ff0000">mdi-star-box</v-icon>
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="sidebar__header_right">
        <v-btn icon @click="addNumber" title="Añadir contacto"><v-icon>mdi-account-plus</v-icon></v-btn>
        <div class="dropdown" >
          <v-btn icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
          <div class="dropdown-content" style="width: 180px;">
              <div><router-link to="phoneselect">Cambiar número</router-link></div>
              <div><span @click="descargarManual">Ver manual</span></div>
              <div><span @click="logout">Cerrar sesión</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar__search">
      <div class="sidebar__search_container">
        <v-btn icon><v-icon>mdi-magnify</v-icon></v-btn>
        <input
            type="text"
            placeholder="Busca una conversación"
            v-model.trim="search"
            v-on:input="filter()"
            ref="input"
            style="width: 100%"
        />
      </div>
      <v-icon @click.stop="showFilterUserMessages($event)" :color="isFiltered">mdi-filter</v-icon>
    </div>
    <div class="sidebar__chats">
      <SideUserDetail
          v-for="(user, index) in filterList"
          :key="index"
          :user="user"
          :userFilter=userFilter
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import SideUserDetail from "@/components/SideUserDetail";
import ContextMenu from "@/components/ContextMenu";

export default {
  name: "Side",
  components: {
    ContextMenu,
    SideUserDetail
  },
  computed: {
    ...mapState(['phoneList']),
    ...mapGetters(['chatList', 'usersList', 'contactList', 'followedList']),

    filterList() {
      if (this.text === 'chats') {
        return this.chatList
      }
      if (this.text === 'agenda') {
        return this.contactList
      }
      return this.followedList
    },
    isFiltered() {
      return this.userFilter !== '' ? "#00a100" :  ""
    },
  },
  data: () => ({
    userFilter: '',
    search: '',
    showContextMenu: false,
    text: 'chats'
  }),
  methods: {
    ...mapActions(['filterChatList','filterContactList', 'filterFollowed', 'emptyContactList', 'getContactList', 'setCurrentPhone', 'setEmptyChat', 'getChatList']),
    addNumber() {
      this.$router.push({
        name: "Number",
      });
    },
    isUserSelected(user) {
      return user === this.userFilter ? "color: #00a100" :  ""
    },
    descargarManual() {
      let url = "https://grupomkd.sharepoint.com/:p:/s/zaragoza/EfhzJdWspnJDuvySueNIx3kBP0h9NdGm1WVWHY_jopqOIw?e=OLVj8J"
      window.open(url, '_blank', 'noreferrer');
    },
    logout() {
      localStorage.removeItem("auth_token")
      this.$router.push('login')
      console.log("Logout")

    },
    async filter() {
      if (this.text === 'chats') {
        this.filterChatList(this.search)
      } else if (this.text === 'agenda') {
        if (this.search.length < 4) {
          this.emptyContactList()
        }
        if ((this.contactList.length < 1) && (this.search.length > 3)) {
          await this.getContactList(this.search)
        }
        this.filterContactList(this.search)
      } else {
        this.filterFollowed(this.search)
      }
    },
    showFilterUserMessages(e) {
      this.$refs.menuUsers.open(e)
    },

    filterUserMessages(user) {
      if (this.userFilter === user) {
        this.userFilter = ""
      } else {
        this.userFilter = user
      }
      this.$refs.menuUsers.close()
    },
  },

  mounted() {
    let loader = this.$loading.show()
    this.getChatList(this.phoneList.current.number)
    this.setEmptyChat()
    loader.hide()
  },
  watch: {
    text: function() {
      this.search = ''
      if (this.text === 'chats') {
        this.filterChatList('')
      } else if (this.text === 'agenda') {
        this.filterContactList('')
      } else {
        this.filterFollowed('')
      }

    }
  }
}
</script>

<style scoped>
  a {
    text-decoration: none;
    color: inherit;
  }
  .sidebar {
    flex: 0.35;
    max-width: 420px;
    min-width: 420px;
  }
  .sidebar__header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-right: 1px solid lightgray;
  }

  .sidebar__header_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 4vw;
    max-width: 6vw;
  }

  .sidebar__search {
    display: flex;
    align-items: center;
    background-color: #f6f6f6 !important;
    height: 39px;
    padding: 10px;
  }

  .sidebar__search_container {
    display: flex;
    align-items: center;
    background-color: white;
    height: 35px;
    border-radius: 20px;
    width: 94%;
    margin-right: 8px;
  }

  .sidebar__search_container > input {
    border: none;
    margin-left: 10px;
  }
  input:focus {
    outline: none;
  }

  .sidebar__chats {
    flex: 1;
    overflow-y: scroll;
    background-color: white;
    height: 79%;
  }

  .sidebar__user {
    display: inline-block;
    vertical-align: middle;
    padding-left: 8px;
  }

  .sidebar__user_description {
    font-weight: bold;
    font-size: 1.2em;
    max-width: 105px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-content > div:hover {
    cursor: pointer;
  }

  .dropdown-content > div:not(:last-child) {
    margin-bottom: 15px;
  }
</style>