<template>
  <v-app id="modal_main">
    <v-layout row justify-center>
      <v-dialog v-model="open" scrollable max-width="40%">
        <v-card>
          <v-card-text class="pt-6">
          <v-row v-if="previewImage" justify="center">
            <div>
              <img class="preview my-3" :src="previewImage" alt="" />
            </div>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-col cols="9">
              <v-file-input
                  counter
                  show-size
                  small-chips
                  label="Seleccionar archivo/s"
                  accept="image/*,application/pdf"
                  @change="selectImage"

              ></v-file-input>
            </v-col>
            <v-col cols="1" class="pl-10">
              <v-btn fab  small color="success" @click="upload">
                <v-icon small >mdi-send</v-icon>
<!--                <v-icon right dark>mdi-cloud-upload</v-icon>-->
              </v-btn>
            </v-col>
          </v-row>
          </v-card-text>
        </v-card>
        <!--
        <v-card>
          <v-card-title>
            <span class="headline">Añadir número 2</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form @submit.prevent="addNumber()"
                      ref="form"
                      v-model="isValid"
                      lazy-validation
              >
                <v-text-field
                    v-model="name"
                    :counter="64"
                    :rules="nameRules"
                    label="Nombre"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="phone_number"
                    :counter="9"
                    :rules="phoneRules"
                    label="Número de movil"
                    required
                ></v-text-field>
                <v-select
                    v-model="select"
                    :items="phoneType"
                    :rules="[v => !!v || 'Debe especificar de quien es el número']"
                    label="Item"
                    required
                ></v-select>
                <v-text-field
                    type="number" pattern="^/d+$"
                    v-model="idEncargo"
                    :rules="[(((select === 'Particular') && (idEncargo !== '')) || ((select !== 'Particular') && (idEncargo === ''))) || 'Rellenar encargo al que pertenece']"
                    label="ID Encargo del cliente particular"
                    digit
                    :required="getRequired()"
                    :disabled="getDisabled()"
                >
                  ></v-text-field>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" @click="open = false">Cerrar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="!isValid" @click="addNumber" type="submit">Guardar</v-btn>
          </v-card-actions>
        </v-card>
        -->
      </v-dialog>
    </v-layout>
  </v-app>
</template>

<script>
//import plataformaService from '@/services/plataforma'
//import { mapActions, mapState } from 'vuex'
export default {
  name: "UploadFile",
  data: () => {
    return {
      currentImage: undefined,
      previewImage: undefined,
      open: true,
    };
  },
  computed: {
    //...mapState(['phoneList'])
  },
  methods: {
    selectImage(image) {
      this.currentImage = image;
      this.previewImage = URL.createObjectURL(this.currentImage);
      let file = image,
      reader = new FileReader();

      reader.onloadend = () => {
        this.base64 = reader.result.replace(/^data:.+;base64,/, '')
      };

/*
      function () {
        // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
        var b64 = reader.result.replace(/^data:.+;base64,/, '');
        this.base64 = b64
        //console.log(b64); //-> "R0lGODdhAQABAPAAAP8AAAAAACwAAAAAAQABAAACAkQBADs="
      };
*/
      reader.readAsDataURL(file);
    },

    upload() {
      if (!this.currentImage) {
        this.message = "Seleccionar un fichero";
        return;
      }
      this.$emit('receiveFileAttached', {
        name: this.currentImage.name,
        type: this.currentImage.type,
        data: this.base64
      })

      this.open = false

      // console.log(this.currentImage.name)
      // console.log(this.currentImage.type)
      // console.log(this.base64)


      // var reader = new FileReader();
      // //reader.readAsDataURL(this.currentImage);
      // reader.readAsDataURL(this.previewImage);
      // reader.onloadend = function () {
      //   var base64String = reader.result;
      //   console.log('Base64 String - ', base64String);
      //
      //   // Simply Print the Base64 Encoded String,
      //   // without additional data: Attributes.
      //   console.log('Base64 String without Tags- ',
      //       base64String.substr(base64String.indexOf(', ') + 1));
      // }
      /*
      UploadService.upload(this.currentImage, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
          .then((response) => {
            this.message = response.data.message;
            return UploadService.getFiles();
          })
          .then((images) => {
            this.imageInfos = images.data;
          })
          .catch((err) => {
            this.progress = 0;
            this.message = "Could not upload the image! " + err;
            this.currentImage = undefined;
          });
          */

    },
  },
  created() {
  },
  mounted() {
  },
  watch: {
    open: function(value) {
      if (value === false) {
        this.$router.push({
          name: "PhoneSelected",
        });
      }
    }
  }
};
</script>

<style scoped>
  #modal_main {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer; /* Add a pointer on hover */
  }

  .preview {
    max-width: 400px;
  }
</style>
