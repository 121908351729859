import plataforma from "@/services/plataforma"

export default {
    state: {
        lists: [
            // { id: 2, number: 655333444, name: "Ambrosio"},
            // { id: 3, number: 658444888, name: "Aniceto Valdivieso"},
        ],
        filter: "",
        filtered_lists: [],
    },
    getters: {
        contactList: (state) => {
            if (state.filter.length) {
                return state.filtered_lists;
            }
            return state.lists
        },
    },
    mutations: {
        EMPTY_CONTACT_LIST(state) {
          state.lists = [];
        },
        UPDATE_CONTACT_LIST(state, data) {
            state.lists = data.data
        },
        SET_CONTACT_FILTER(state, data) {
            state.filter = data.toLowerCase()
        },
        FILTER_CONTACT_LIST(state) {
            if (!state.lists) {
                return
            }
            state.filtered_lists = state.lists.filter(list => {
                return list.name.toString().toLowerCase().includes(state.filter) || list.number.toString().toLowerCase().includes(state.filter)
            })
        }
    },
    actions: {
        emptyContactList({commit}) {
            commit('EMPTY_CONTACT_LIST')
        },
        getContactList ({commit}, input) {
             return plataforma.getContacts(input)
                 .then(response => {
                     commit('UPDATE_CONTACT_LIST', response.data)
                 }).catch(error => {
                     throw (error)
                 })
        },
        filterContactList ({commit}, input) {
            commit('SET_CONTACT_FILTER', input)
            commit('FILTER_CONTACT_LIST')
        },
        setAdded({commit}, data) {
            commit('UPDATE_CONTACT_LIST', data)
        }
    }
}