import axios from 'axios'
//import login from '@/store/module/login'
//import jwt_decode from "jwt-decode";
const apiClient = axios.create({
    baseURL: process.env.VUE_APP_RUTA_PLATAFORMA,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})

export default {
    downloadFile(id) {
        return apiClient.get('/get_file.php?id=' + id, { responseType: 'blob' })
    },
    getChat(number_client, number_wa, isGroup, todos) {
        return apiClient.get('/chat.php?number_client=' + number_client + '&number_wa=' + number_wa + '&is_group=' + isGroup + '&todos=' + todos )
    },
    getChatsList(number_wa) {
        return apiClient.get('/chat_list.php?number_wa=' + number_wa)
    },
    getContacts(input) {
        return apiClient.post('/get_contacts.php', { search: input })
    },
    getMultimedia: (id) => {
        return apiClient.get('/get_multimedia.php?id=' + id)
    },
    getPhones: () => {
        return apiClient.get('/phones.php')
    },
    getTemplates(send_number, id_encargo) {
        return apiClient.get('/get_templates.php?send_number=' + send_number + '&id_encargo=' + id_encargo)
    },
    insertFile({ name, type, data, send_to, send_from, id_tecnico }) {
        return apiClient.post('/insert_file.php', { name: name, type: type, data: data, send_to: send_to, send_from: send_from, id_tecnico: id_tecnico })
    },
    insertMessage({ message, send_to, send_from, id_tecnico, is_group }) {
        return apiClient.post('/insert_message.php', { message: message, send_to: send_to, send_from: send_from, id_tecnico: id_tecnico, is_group: is_group })
    },
    insertNumber({ name, number, id_encargo }) {
        return apiClient.post('/insert_number.php', { name: name, number: number, id_encargo: id_encargo })
    },
    isNumberAllowedSend(number_client, number_wa) {
        return apiClient.get('/allowed_at.php?number_client=' + number_client + '&number_wa=' + number_wa )
    },
    login({username, password}) {
        return apiClient.post('/login.php', { username: username, password: password })
    },
    markUnread(number_client, number_wa) {
        return apiClient.post('mark_unread.php', { number_client: number_client, number_wa : number_wa })
    },
    saveAttach(ids, id_encargo) {
        return apiClient.post('save_attach.php', {ids: ids, id_encargo: id_encargo})
    },
    sendTemplate(index, id_encargo, number_client, number_wa, id_tecnico) {
        return apiClient.post('send_meta.php', {index: index, id_encargo: id_encargo, number_client: number_client, number_wa : number_wa, id_tecnico: id_tecnico })
    },
    setRead(number_client, number_wa, is_group) {
        return apiClient.post('set_read.php', { number_client: number_client, number_wa : number_wa,  is_group: is_group })
    },
}

apiClient.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('auth_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${ token }`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
apiClient.interceptors.response.use((response) => {
    if (response.data.token) {
        localStorage.setItem('auth_token', response.data.token)
    }
    return response;
}, (error) => {
    return Promise.reject(error.message);
});
