import plataformaService from '@/services/plataforma'

export default {
    state: {
        current: {
            id: null,
            number: null,
            description: null,
            particular_number: null,
            meta: null,
            meta_id: null
        }
        //     {
        //     id: 3,
        //     number: 681944185,
        //     description: "Generali Manual"
        // }
        ,
        phones: [
            // { number: 1, description: "este" },
            // { number: 2, description: "otro" },
        ],
    },
    mutations: {
        SET_PHONES(state, phones) {
            state.phones = phones
        },
        SET_CURRENT_PHONE(state, phone) {
            state.current.id = phone.id
            state.current.number = phone.number
            state.current.description = phone.description
            state.current.particular_number = phone.particular_number
            state.current.meta = phone.meta
            state.current.meta_id = phone.meta_id
            localStorage.setItem('current', JSON.stringify(state.current))
        },
    },
    actions: {
        fetchPhones({ commit }) {
            return plataformaService.getPhones()
                .then(response => {
                    commit('SET_PHONES', response.data)
                })
                .catch(error => {
                    throw (error)
                })
        },
        setCurrentPhone({commit}, phone) {
            commit('SET_CURRENT_PHONE', phone)
        }
    }
}

