<template>
  <v-app id="modal_main">
    <v-layout row justify-center>
      <v-dialog v-model="open" scrollable max-width="40%">
        <v-card>
          <v-card-title>
            <span class="headline">Añadir número</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form @submit.prevent="addNumber()"
                ref="form"
                v-model="isValid"
                lazy-validation
              >
                <v-text-field
                    v-model="name"
                    :counter="64"
                    :rules="nameRules"
                    label="Nombre"
                    required
                ></v-text-field>
                  <v-text-field
                      v-model="phone_number"
                      :counter="9"
                      :rules="phoneRules"
                      label="Número de móvil"
                      required
                  ></v-text-field>
                <v-select
                    v-model="select"
                    :items="phoneType"
                    :rules="[v => !!v || 'Debe especificar de quien es el número']"
                    label="Tipo de contacto"
                    required
                ></v-select>
                <v-text-field
                    type="number" pattern="^/d+$"
                    v-model="idEncargo"
                    :rules="[(((select === 'Particular') && (this.phoneList.current.particular_number === 0)) || ((select === 'Particular') && (idEncargo !== '')) || ((select !== 'Particular') && (idEncargo === ''))) || 'Rellenar encargo al que pertenece']"
                    label="ID Encargo del cliente particular"
                    digit
                    :required="getRequired()"
                    :disabled="getDisabled()"
                >
                ></v-text-field>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" @click="open = false">Cerrar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="!isValid" @click="addNumber" type="submit">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-app>
</template>

<script>
import plataformaService from '@/services/plataforma'
import { mapActions, mapState } from 'vuex'
export default {
  name: "NumberAdd",
  data: () => {
    return ({
      requiredEncargo: true,
      isValid: true,
      phone_number: "",
      name: '',
      nameRules: [
        v => !!v || 'Nombre es obligatorio',
        //v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      phoneRules: [
        v => !!v || 'Número de móvil es obligatorio',
        v => /^[67]\d{8}$|\d+@g\.us$/.test(v) || 'El número no es válido (tiene que tener nueve dígitos)'
      ],
      phoneType: [
        'Taller / Perito',
        'Particular',
      ],
      select: '',
      open: true,
      idEncargo: ''
    });
  },
  computed: {
    ...mapState(['phoneList'])
  },
  methods: {
    ...mapActions(['setCurrentChat', 'getMessagesFromNumber', 'setAdded', 'getChatList']),
    getRequired() {
      return this.select === 'Particular' && this.phoneList.current.particular_number === 1
    },
    getDisabled() {
      if ((this.select === 'Particular') && (this.phoneList.current.particular_number === 1)) {
        return false
      }
      this.idEncargo = '';
      return true
    },
    validate() {
      //console.log(this.$refs.form)

    },
    async addNumber() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let loader = this.$loading.show()
      let response = await plataformaService.insertNumber( {name: this.name, number: this.phone_number, id_encargo: this.idEncargo })
      const numberId = response.data.id;
      let phone = {
        id: numberId,
        name: this.name,
        number: parseInt(this.phone_number)
      }
      await this.setCurrentChat(phone)
      await this.getChatList(this.phoneList.current.number)
      let objDiv = document.getElementById('chat__body')
      objDiv.scrollTop = objDiv.scrollHeight
      this.open = false;
      this.setAdded(phone)
      loader.hide()
    },
  },
  created() {
    this.select = 'Taller / Perito'
    if (this.$route.params.id_encargo) {
      this.select = 'Particular'
      this.idEncargo = this.$route.params.id_encargo
    }
    this.phone_number = this.$route.params.number
    this.name = this.$route.params.name
  },
  mounted() {
  },
  watch: {
    open: function(value) {
      if (value === false) {
        this.$router.push({
          name: "PhoneSelected",
        });
      }
    }
  }
};
</script>

<style scoped>
  #modal_main {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer; /* Add a pointer on hover */
  }
</style>
