import chats from './chats'

export default {
    state: {
        lists: [],
        filter: "",
        filtered_lists: [],
    },
    getters: {
        followedList: (state) => {
            if (state.filter.length) {
                return state.filtered_lists;
            }
            return state.lists
        },
    },
    mutations: {
        SET_FILTER(state, data) {
            state.filter = data.toLowerCase()
        },
        FILTER_FOLLOWED(state) {
            if (localStorage.getItem('follow') !== null) {
                let follow = JSON.parse(localStorage.getItem('follow'))
                state.lists = chats.state.lists.filter(list => follow.includes(list.number))
            }
            if (state.filter !== "") {
                state.filtered_lists = state.lists.filter(list => {
                    return list.name.toLowerCase().includes(state.filter) || list.number.toString().toLowerCase().includes(state.filter)
                })
            }
        }
    },
    actions: {
        filterFollowed({commit}, input) {
            commit('SET_FILTER', input)
            commit('FILTER_FOLLOWED')
        }
    }
}