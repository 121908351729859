import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/Login.vue'
import Config from '@/views/Config.vue'
import NumberAdd from '@/components/NumberAdd.vue'
import SendTemplate from '@/components/SendTemplate.vue'
import PhoneSelect from '@/views/PhoneSelect.vue'
import PhoneSelected from '@/views/PhoneSelected.vue'
import UploadFile from '@/components/UploadFile.vue'
import UploadMultiple from '@/components/UploadMultipleFiles.vue'
import jwt_decode from "jwt-decode";
//import socket from '@/socket'

import io from 'socket.io-client'

Vue.use(Router)

export const router = new Router({
    routes: [
        {
            path: '/chat',
            name: 'PhoneSelected',
            component: PhoneSelected,
            children: [
                {
                    path: "number",
                    components: { number: NumberAdd },
                    name: "Number",
                    meta: { Auth: true },
                },
                {
                    path: "sendtemplate",
                    components: { send_template: SendTemplate },
                    name: "SendTemplate",
                    meta: { Auth: true },
                },
                {
                    path: "upload",
                    components: { upload: UploadFile},
                    name: "UploadFile",
                    //meta: { Auth: true },
                },
            ],
            beforeEnter (to, from, next) {
                let current = JSON.parse(localStorage.getItem('current'))
                Vue.prototype.$socket = io(process.env.VUE_APP_RUTA_SOCKET,  {query: 'auth_token='+ localStorage.getItem('auth_token') +'&number=' + current.number })
                next()
            },
            meta: { Auth: true },
        },
        {
            path: '/config',
            name: 'Config',
            component: Config,
            beforeEnter (to, from, next) {
                Vue.prototype.$socket = io(process.env.VUE_APP_RUTA_SOCKET,  {query: 'auth_token='+ localStorage.getItem('auth_token') + '&number' })
                next()
            },
            meta: { Auth: true },
        },
        {
            path: '/',
            name: 'PhoneSelect',
            component: PhoneSelect,
            alias: '/phoneselect',
            meta: { Auth: true },
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: { Auth: false },
        },
        // {
        //     path: '/upload',
        //     name: 'Upload',
        //     component: UploadFile,
        //     meta: { Auth: true },
        // },
        {
            path: '/uploadmultiple',
            name: 'UploadMultiple',
            component: UploadMultiple,
            meta: { Auth: true },
        },
        { path: '*', redirect: '/' }
    ]
})

router.beforeEach((to, from, next) => {
    //const publicPages = ['/login'];
    //const authRequired = !publicPages.includes(to.path);
    //const loggedIn = localStorage.getItem('auth_token');
    //console.log('auth required', authRequired)
    //console.log('logged', loggedIn)
    //

    if (to.meta.Auth) {
        if (!localStorage.getItem('auth_token')) {
            next({ path: '/login' });
        }
        let decoded = jwt_decode(localStorage.getItem('auth_token'))
        //fallo al decodificar. no valido o no existe
        if (!decoded) {
            next({ path: '/login' });
        }
        //ha caducado
        if (Date.now() >= decoded.exp * 1000) {
            next({ path: '/login' });
        }
    }

    // if (authRequired && !loggedIn) {
    //      return next('/login');
    // }
    // let current = JSON.parse(localStorage.getItem('current'))
    // if (!current?.number && (to.path !== '/phoneselect'))  {
    //     return next('/phoneselect')
    // }
    // redirect to login page if not logged in and trying to access a restricted page
    // const publicPages = ['/login'];
    // const authRequired = !publicPages.includes(to.path);
    // const loggedIn = localStorage.getItem('user');
    //
    // if (authRequired && !loggedIn) {
    //     return next('/login');
    // }

    next();
})
