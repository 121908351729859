import plataformaService from "@/services/plataforma"

export default {
    state: {
        lists: [
            // { id: 1, number: 652444580, name: "Woody"},
            // { id: 2, number: 655333444, name: "Ambrosio"},
            // { id: 3, number: 658444888, name: "Aniceto Valdivieso"},
            // { id: 4, number: 639051583, name: "Jorge"},
            // { id: 5, number: 681944185, name: "Num Generali"}
            // //{ id: 5, number: 657432469, name: "Gabriel"},
        ],
        filter: "",
        filtered_lists: [],
        users: [],
    },
    getters: {
        chatList: (state) => {
            if (state.filter.length) {
                return state.filtered_lists;
            }
            return state.lists
        },
        usersList: (state) => {
            return state.users
        },
        // export function getByKeyword(list, keyword) {
        //     const search = keyword.trim().toLowerCase()
        //     if (!search.length) return list
        //     return list.filter(item => item.name.toLowerCase().indexOf(search) > -1)
        // }
    },
    mutations: {
        UPDATE_LIST(state, data) {
            state.lists = data.data
            let users = data.data.filter(function (number) {
                return typeof number.tecnico_name !== 'undefined'
            }).map(function(user) {
                return user.tecnico_name
            })
            state.users = users.filter((x, i, a) => a.indexOf(x) === i).sort();
        },
        SET_FILTER(state, data) {
            state.filter = data.toLowerCase()
        },
        FILTER_LIST(state) {
            if (!state.lists) {
                return
            }
            state.filtered_lists = state.lists.filter(list => {
                return list.name.toString().toLowerCase().includes(state.filter) || list.number.toString().toLowerCase().includes(state.filter)
            })
        },
        RESET_LIST_NUMBER(state, data) {
            if (!state.lists) {
                return
            }
            let chat = state.lists.findIndex(function(chat) {
                return parseInt(this.client) === chat.number
            }, data )
            if (chat !== -1) {
                state.lists[chat].unread = 0;
                state.lists[chat].marked_unread = 0;
            }
        },
        SET_UNREAD(state, data) {
            if (!state.lists) {
                return
            }
            let chat = state.lists.findIndex(function(chat) {
                return parseInt(this.client) === chat.number
            }, data )
            if (chat !== -1) {
                state.lists[chat].marked_unread = 1;
            }
        }
    },
    actions: {
        getChatList ({ commit }, number) {
            return plataformaService.getChatsList(number)
                .then(response => {
                    commit('UPDATE_LIST', response.data)
                }).catch(error => {
                    throw (error)
                })
        },
        getUsers({ commit }) {
            commit('GET_USERS')
        },
        filterChatList ({commit}, input) {
            commit('SET_FILTER', input)
            commit('FILTER_LIST')
        },
        markRead({commit}, data) {
            commit('RESET_LIST_NUMBER', data)
        },
        markUnread({commit}, data) {
            commit('SET_UNREAD', data)
            return plataformaService.markUnread(data.client, data.whatsapp)
        },
        resetUnread({commit}, data) {
            commit('RESET_LIST_NUMBER', data)
            return plataformaService.setRead(data.client, data.whatsapp, data.isGroup)
        },
    }
}