<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>

import { mapState } from "vuex";

export default {
  name: 'App',
  computed: {
    ...mapState({ isLoggedIn: (state) => state.isLoggedIn }),
  },
  components: {
    // Side,
    // Chat,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
body {
  font-family: Roboto, Helvetica, Arial !important;
}
.app {
  display: grid !important;
  place-items: center !important;
  background-color: #dadbd3 !important;
  height: 100vh !important;
}

.app__body {
  display: flex;
  background-color: #ededed !important;
  width: 90vw !important;
  max-width: 1400px;
  height: 90vh !important;
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.2);
}
</style>