<template>
  <div>
    <div class="title">Seleccionar el whatsapp con el que desea trabajar:</div>
    <div class="divTable">
      <div class="divTableHeading">
        <div class="divTableHead">NUMERO</div>
        <div class="divTableHead">DESCRIPCION</div>
        <div class="divTableHead"></div>
      </div>
      <div class="divTableBody">
        <div v-for="(phone, index) in phoneList.phones"
             :key="index"
             :phone="phone"
             class="divTableRow"
             @click="selectPhone(index)"
        >
          <div class="divTableCell">{{phone.number}}</div>
          <div class="divTableCell">{{phone.description}}</div>
          <div class="divTableCell click" ><v-icon color="#2D6E00">mdi-cellphone-check</v-icon></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
  name: "PhoneSelect",
  data: () => ({
    //phones: [],
  }),
  computed: {
    ...mapState(['phoneList', 'login']),
  },
  watch: {
    fetchPhones: {
      handler() {
          //this.searchPhones();
        this.fetchPhones()
      },
      immediate: true
    },
  },
  methods: {
    ...mapActions(['fetchPhones', 'setCurrentPhone']),
    searchPhones() {
      this.fetchPhones()
    },
    async selectPhone(id) {
      this.setCurrentPhone(this.phoneList.phones[id])
      this.$router.push('chat')
    }
  },
}
</script>

<style scoped>
  .title {
    font-size: 1.2em;
    margin-bottom: 10px;
    font-weight: bold;
    color: #007a8b;
  }
  .divTable{
    display: table;
    width: 100%;
  }
  .divTableRow {
    display: table-row;
  }
  .divTableHeading {
    background-color: #EEE;
    display: table-header-group;
  }
  .divTableCell, .divTableHead {
    display: table-cell;
    padding: 10px;
    text-align: center;
    font-size: 0.9em;
  }
  .divTableHeading {
    background-color: #EEE;
    display: table-header-group;
    font-weight: bold;
  }
  .divTableBody {
    display: table-row-group;
  }
  .click {
    cursor: pointer;
  }
</style>