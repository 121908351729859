<template>
  <div class="container">
    <div class="element formulario">
      <v-form @submit.prevent="addNumber()"
        ref="form"
        lazy-validation
      >
        <v-text-field
          v-model="id"
          solo
          dense
          name="id"
          :rules="rules"
          required
          label="ID"
          placeholder="ID"
          type="text"
        ></v-text-field>
        <v-text-field
          v-model="description"
          solo
          dense
          name="description"
          :rules="rules"
          required
          label="Descripción"
          placeholder="Descripción"
          type="text"
        ></v-text-field>
        <v-text-field
          v-model="number"
          solo
          dense
          name="number"
          :counter="9"
          :rules="phoneRules"
          required
          label="Número"
          placeholder="Número"
          type="digit"
        ></v-text-field>
        <div class="login_container">
          <button type="button" name="button" class="btn login_btn" @click.prevent="addNumber()">
            Añadir número
          </button>
        </div>
      </v-form>
    </div>
    <div class="element bidi"><img :src="bidi" alt="" style="height: 350px"></div>
    <div class="element messages">
      <p v-for="(phone, index) in phoneList.phones"
            :key="index"
            class="divTableRow"
      >
      {{index}} - {{phone.number}}
    </p>
      <hr>
      <p v-for="(message, index) in messages" :key="index"><span class="blue">{{message.currentDate}}</span>  <span :class="message.style">{{message.message}}</span></p>
    </div>
  </div>
</template>



<script>
  import axios from "axios";
  import { mapState } from 'vuex'

  export default {
    name: "Config",
    computed: {
      ...mapState(['phoneList']),
    },
    destroyed: function () {
      this.$socket.disconnect()
    },
    data: () => ({
      bidi: "",
      description: "",
      id: "",
      messages: [],
      number: "",
      rules: [
        v => !!v || 'Obligatorio'
      ],
      phoneRules: [
        v => !!v || 'Obligatorio',
        v => /^[67]\d{8}$/.test(v) || 'El número no es válido (tiene que tener nueve dígitos)'
      ],
    }),
    methods: {
      currentDate() {
        let date = new Date();
        return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + (date.getDate()).toString().padStart(2, '0') + " " +  (date.getHours()).toString().padStart(2, '0') + ":" + (date.getMinutes()).toString().padStart(2, '0') + ":" + (date.getSeconds()).toString().padStart(2, '0');
      },
      async addNumber() {
        if (!this.$refs.form.validate()) {
          return;
        }
        let post = {
          id: this.id,
          description: this.description,
          number: this.number
        };
        await axios.post(process.env.VUE_APP_RUTA_ADD, post)
      },
      updateBidi(payload) {
        this.bidi = payload.bidi
      },
      updateStatus({message, style}) {
        let currentDate = this.currentDate()
        this.messages.push({ currentDate, message, style })
      },
    },
    mounted() {
      this.$socket.on('show_bidi', payload => this.updateBidi(payload))
      this.$socket.on('status_message', payload => this.updateStatus(payload))
    }
  }
</script>

<style scoped>
.container {
  display: grid;
  grid-gap: 10px;
  background-color: #fff;
  grid-template-columns: 50% 50%;
  height: 90vh;
  width: 70%;
}
.element {
  padding: 20px;
}
.formulario {
  grid-column: 1;
  align-self: center;
}
.messages {
  grid-column: 2 ;
  grid-row: 1 / span 2;
  border-left: solid 1px #444444;
  overflow:scroll;
  overflow-x:hidden;
  font-family: monospace;
  font-size: 13px;
}
.bidi {
  grid-column: 1 ;
  grid-row: 2 ;
  align-self: center;
  justify-self: center;
}
.btn {
    font-weight: bold;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1.1rem;
    line-height: 1.5;
    border-radius: .25rem;
    width: 100%;
    background: #007a8b !important;
    color: white !important;
  }
  .green {
    color: green;
  }
  .blue {
    color: blue;
  }
  >>> .error--text {
    color: rgba(255, 0, 0, 0.7) !important;
  }
</style>