import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import login from './module/login'
import currentChat from './module/currentChat'
import phoneList from './module/phoneList'
import chats from './module/chats'
import contacts from './module/contacts'
import followed from './module/followed'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['login', 'phones']
  })],
  state: {
    isLoggedIn: true,
  },
  mutations: {
    initialiseStore(state) {
      if (localStorage.getItem('current')) {
        state.phoneList.current = JSON.parse(localStorage.getItem('current'))
      }
    },
  //   initialiseStore(state) {
  //     // Check if the ID exists
  //     console.log(typeof state)
  //     console.log(JSON.parse(localStorage.getItem('current')))
  //     let clonedArrayES6 = {...state, current: JSON.parse(localStorage.getItem('current'))}
  //     console.log(typeof clonedArrayES6)
  //     console.log(clonedArrayES6)
  //     this.replaceState(
  //       Object.assign(state, clonedArrayES6)
  //     );
  //     // if(localStorage.getItem('current')) {
  //     //   // Replace the state object with the stored item
  //     //   console.log("Lo que hay guardado", localStorage.getItem('current'))
  //     //   this.replaceState(
  //     //       Object.assign(state.phoneList.current, JSON.parse(localStorage.getItem('current')))
  //     //   );
  //     // }
  //   }
  },
  actions: {
    
  },
  modules: {
    chats,
    currentChat,
    login,
    phoneList,
    contacts,
    followed,
  },
})
