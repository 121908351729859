<template>
  <div class="res-circle">
    <div class="circle-txt">{{number}}</div>
    <div v-if="withAttach > 0" class="badge">&nbsp;</div>
  </div>
</template>

<script>
export default {
  name: "DataCircle",
  props: {
    number: Number,
    withAttach: Number,
  }
}
</script>

<style scoped>
  .res-circle {
    width: 20px;
    border-radius: 50%;
    background: #1E90FF;
    line-height: 0;
    position: relative;
    display: inline-block;
    top: 5px;
  }

  .res-circle::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .circle-txt {
    font-family: arial, sans-serif;
    font-weight: bold;
    color: white;
    font-size: 12px;
    /* (E1) CENTER TEXT IN CIRCLE */
    position: absolute;
    bottom: 50%;
    width: 100%;
    text-align: center;
  }
  .verde {
    background-color: #44853b;
  }
  .badge {
    display: inline-block;
    position: absolute;
    top: -2px;
    right: -2px;
    width: 8px;
    height: 8px;
    padding: 4px; /* rem unit */
    border-radius: 50%;
    background: #ff0000;
  }
</style>