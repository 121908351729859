import jwt_decode from "jwt-decode";

export default {
    state: {
        //token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MzE4MDY1NjksImRhdGEiOnsiaWQiOjAsImFyZWEiOiJDb250cm9sIn19.ovBF59Ac_LrzZQte1GTzvSAQ0MKYvEFN1WVWpzcSzeA',
        token: null,
        id_tecnico: null,
    },
    mutations: {
        SET_ID_TECNICO(state, id_tecnico) {
            state.id_tecnico = id_tecnico
        },
        SET_TOKEN(state, token) {
            state.token = token
            localStorage.setItem('auth_token', token)
        }
    },
    actions: {
        setToken({commit}, token) {
            let decoded = jwt_decode(token);
            // if (Date.now() >= decoded.exp * 1000) {
            //     console.log('caducado')
            // }
            commit('SET_TOKEN', token)
            commit('SET_ID_TECNICO', parseInt(decoded.data.id || 0));
        }
    }
}