<template>
    <div class="app__body">
      <Side />
      <Chat />
      <router-view :key="$route.fullPath" name="number"></router-view>
    </div>
</template>

<script>
import Side from "@/views/Side";
import Chat from "@/views/Chat";
import { mapState } from "vuex";
export default {
  name: "PhoneSelected",
  computed: {
    ...mapState({ isLoggedIn: (state) => state.isLoggedIn  }),
  },
  components: {
    Side,
    Chat,
  },
}
</script>

<style scoped>

</style>