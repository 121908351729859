import plataformaService from '@/services/plataforma'
import chats from '@/store/module/chats'
import phoneList from "@/store/module/phoneList";

export default {
    state: {
        allowedAt: null,
        chatMessages: [],
        // [
        //     {
        //         senderNumber: 658444888,
        //         message: "Texto de prueba recibido",
        //         time: "14:58"
        //     },
        //     {
        //         senderNumber: 652444580,
        //         message: "Texto de prueba enviado",
        //         time: "15:00"
        //     }
        // ],
        id: null,
        id_encargo: null,
        is_group: null,
        name: null,
        number: null,
        followed: false,
        not_registered: null,
        totalOfMessages: 0,
    },
    mutations: {
        async UPDATE_CHAT(state, data) {
            if (((state.number !== null) && (data.send_from?.toString() === state.number.toString()) && (data.add !== false) && data.deleted !== 1)
                || ((state.number !== null) && (data.send_from?.toString() === phoneList.state.current.number.toString()) && (data.send_to?.toString() === state.number.toString()) && data.deleted !== 1 )
                || ((data.add) && (data.send_to?.toString() === state.number.toString())) ) {
                let date = new Date();
                if (data.multimedia && !data.add) {
                    //TODO EN ESTE CASO ESTA LO RECIBIDO SIN REDUCIR.
                    let multimedia = await plataformaService.getMultimedia(data.id_db)
                    data.media_data = multimedia.data.data.media_data
                    data.media_type = multimedia.data.data.media_type
                    data.media_name = multimedia.data.data.media_name
                }
                //TODO MIRAR DE RECIBIRLO EN EL SOCKET. POR AHORA LO DEJO POR TIMEZONE DE LAS N...
                state.chatMessages.push({
                    message_date: data.message_date || date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds(),
                    //message_date: data.message_date,
                    id: data.id,
                    send_from: data.send_from,
                    message: data.body,
                    media_data: data.media_data,
                    media_type: data.media_type,
                    media_name: data.media_name,
                    replied: data.replied
                })
            } else if ((state.number !== null) &&(data.send_from?.toString() === phoneList.state.current.number.toString()) && (data.send_to?.toString() === state.number.toString()) && (data.deleted === 1)) {
                let message = state.chatMessages.findIndex(function(message) {
                    return message.id === data.id
                }, data )
                state.chatMessages.push({ ...state.chatMessages[message], deleted: 1})
                state.chatMessages.splice(message, 1);

            } else {
                let chat = chats.state.lists.findIndex(function(chat) {
                    return parseInt(this.send_from) === chat.number
                }, data )
                if (chat >= 0) {
                    chats.state.lists[chat].unread += 1;
                }
            }
        },
        SET_PHONE(state, phoneData) {
            state.id = phoneData.id
            state.id_encargo = phoneData.id_encargo
            state.is_group = phoneData.is_group
            state.name = phoneData.name
            state.number = phoneData.number
            state.not_registered = phoneData.not_registered
            if (localStorage.getItem('follow') !== null) {
                let follow = JSON.parse(localStorage.getItem('follow'))
                state.followed = follow.includes(phoneData.number)
            }
        },
        SET_ALLOWED(state, message) {
          state.allowedAt = message.allowed_at
        },
        SET_MESSAGES(state, messages) {
            state.chatMessages = messages.data
            state.totalOfMessages = messages.total
        },
        MARK_FOLLOWED(state) {
            state.followed = true
        },
        MARK_NOT_FOLLOWED(state) {
            state.followed = false
        },
    },
    actions: {
        clientUpdate ({ commit }, payload) {
            commit('UPDATE_CHAT', payload)
        },
        getIsAllowed({commit}, data) {
            return plataformaService.isNumberAllowedSend(data.client, data.whatsapp)
                .then(response => {
                    commit('SET_ALLOWED', response.data)
                }).catch(error => {
                    throw (error)
                })
        },
        getMessagesFromNumber({commit}, data ) {
            return plataformaService.getChat(data.client, data.whatsapp, data.isGroup, data.todos)
                .then(response => {
                    commit('SET_MESSAGES', response.data)
                }).catch(error => {
                    throw (error)
                })
        },
        setCurrentChat({commit}, phoneData) {
            commit('SET_PHONE', phoneData)
        },
        setEmptyChat({commit}) {
            commit('SET_PHONE', {id: null, number: null, name: null})
            commit('SET_MESSAGES', { data: [] })
        }
    }
}