<template>
  <v-app id="modal_main">
    <v-layout row justify-center>
      <v-dialog v-model="open" scrollable min-width="600px" max-width="40%" >
        <v-card>
          <v-card-title style="background-color: #c6c6c6" class="mb-3">
            <span class="headline">Enviar plantilla</span>
          </v-card-title>
          <v-card-text>
            <v-container v-if="auto" class="font-weight-bold text-center text-body-2 red--text text-uppercase pa-2 mb-4 yellow">
              Debe enviar una plantilla<br />y esperar a que el usuario responda
            </v-container>
              <v-list-item three-line v-for="(template, index) in templates" v-bind:key="index">
                <v-list-item-content @click="selectTemplate(index)" :class="selected == index ? 'template__selected' : ''">
                  <v-list-item-title>{{ template.descripcion }}</v-list-item-title>
                  <v-list-item-subtitle>{{ template.texto }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" @click="open = false">Cerrar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="0" small :disabled="!selected" @click="sendTemplate" type="submit">Envíar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-app>
</template>

<script>
import plataformaService from '@/services/plataforma'
import { mapActions, mapMutations, mapState, } from 'vuex'
export default {
  name: "SendTemplate",
  data: () => {
    return ({
      auto: 0,
      open: true,
      selected: null,
      templates: [],
    });
  },
  computed: {
    ...mapState(['currentChat', 'login', 'phoneList'])
  },
  methods: {
    ...mapActions([
      'setToken',
    ]),
    ...mapMutations([
      'UPDATE_CHAT', 'SET_TOKEN'
    ]),

    async getTemplates() {
      this.templates = []
      let response = await plataformaService.getTemplates( this.phoneList.current.number, this.currentChat.id_encargo )
      this.templates = response.data.templates
    },
    selectTemplate(index) {
      this.selected = index
    },
    async sendTemplate() {
      let response = await plataformaService.sendTemplate( this.selected, this.currentChat.id_encargo, this.currentChat.number, this.phoneList.current.number, this.login.id_tecnico )
      let date = new Date()
      const messageId = response.data.id;
      let messageData = {
        id: messageId,
        body: this.templates[this.selected].texto,
        send_from: this.phoneList.current.number,
        send_to: this.currentChat.number,
        meta: this.phoneList.current.meta,
        message_date: date.getFullYear() + '-' + (date.getMonth()+1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0') + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds(),
      }

      this.$socket.emit('send_message', messageData)
   //   this.setToken(response.data.token)
      this.UPDATE_CHAT({...messageData, deleted: 0, add: true})

      this.selected = null
      this.open = false
    }
  },
  created() {
    this.auto = this.$route.params.auto || 0
    this.getTemplates()
  },
  mounted() {

  },
  watch: {
    open: function(value) {
      if (value === false) {
        this.$router.push({
          name: "PhoneSelected",
        });
      }
    }
  }
};
</script>

<style scoped>
  #modal_main {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 20;
    cursor: pointer; /* Add a pointer on hover */
  }
  .template_detail {
    border: solid 1px #C6C6C6;
    border-radius: 6px;
    padding: 10px;
    background-color: red;
  }

  .template__selected {
    background-color: #ebebeb;
  }

  .v-list-item__content {
    padding-left: 10px;
    border-radius: 4px;
  }

  div > div.template_detail:not(:last-child) {
    margin-bottom: 10px;
  }
</style>
