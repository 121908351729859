import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//import socket from './socket'
import VueCookies from 'vue-cookies'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

//Lo importo global, no acaba de funcionar en la vista directamente
import contenteditable from 'vue-contenteditable'
Vue.use(contenteditable)

//Vue.prototype.$socket = socket
Vue.use(vuetify)
Vue.use(VueCookies)
Vue.use(VueLoading, {
      canCancel: true,
      backgroundColor: '#000000',
      height: 100,
      width: 100,
      color: '#ff0008',
      opacity: 0.7
    }
);
Vue.config.productionTip = false
Vue.config.devtools = true
new Vue({
  store,
  beforeCreate() {
    this.$store.commit('initialiseStore');
  },
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
