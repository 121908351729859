<template>
  <div
      class="sidebar__chat"
      @click="getCurrentChat(user)"
      :class="getClass(user)"
      v-if="userFilter === '' || (user.tecnico_name !== '' && user.tecnico_name === userFilter ) "
  >
    <ContextMenu :display="showContextMenu" ref="menu">
      <div @click.stop="markNumberUnread">Marcar como no leído</div>
    </ContextMenu>
    <div class="sidebar__chat_info">
      <div>
        <span class="sidebar__chat_info_name">{{ user.name }}</span>
        <span class="sidebar__chat_info_company_name" v-if="user.company_name">{{ user.company_name }}</span>
        <span class="sidebar__chat_info_tecnico_name" v-if="user.tecnico_name">{{ user.tecnico_name }}</span>
      </div>

      <p v-if="!user.is_group">{{ user.number }}</p>
      <p v-else><span class="group">GRUPO</span></p>
    </div>
    <v-spacer />
    <div style="text-align: right;">
      <div class="sidebar__last_message">
        <span v-if="user.created_at">{{showMessageDate(user.created_at) }}</span>
      </div>
      <div>
        <DataCircle  v-if="user.unread > 0" :number="user.unread" :withAttach="user.with_attach"></DataCircle>
        <DataCircle  v-if="user.marked_unread" />
        <v-icon v-if="!user.marked_unread" class="hide" @click.stop="numberOptions($event, user.number)">mdi-chevron-down</v-icon>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DataCircle from "@/components/DataCircle";
import ContextMenu from "@/components/ContextMenu";

export default {
  name: "SideUserDetail",
  components: {
    ContextMenu,
    DataCircle
  },
  data() {
    return {
      phoneTarget: 0,
      showContextMenu: false,
    }
  },
  props: ["user", "userFilter"],
  computed: {
    ...mapState(['login', 'phoneList'])
  },
  methods: {
    ...mapActions(['markUnread', 'setCurrentChat', 'getIsAllowed', 'getMessagesFromNumber', 'resetUnread']),
    getClass(user) {
      return {
        'selected': user.number === this.$store.state.currentChat.number,
        'tecnico': user.id_tecnico === this.login.id_tecnico,
        'not_registered': user.not_registered
      }
    },
    async getCurrentChat(phone) {
      let loader = this.$loading.show()
      await this.setCurrentChat(phone)
      await this.getIsAllowed({ client: phone.number, whatsapp: this.phoneList.current.number })
      await this.getMessagesFromNumber( { client: phone.number, whatsapp: this.phoneList.current.number, isGroup: phone.is_group, todos: 0 })
      await this.resetUnread( { client: phone.number, whatsapp: this.phoneList.current.number, isGroup: phone.is_group })
      this.$socket.emit('reset_unread', { client: phone.number, whatsapp: this.phoneList.current.number, isGroup: phone.is_group })
      let objDiv = document.getElementById('chat__body')
      objDiv.scrollTop = objDiv.scrollHeight
      loader.hide()
      document.getElementsByClassName('selected')[0].scrollIntoView({block: "center", behavior: 'smooth'})
    },
    markNumberUnread() {
      this.markUnread({ client: this.phoneTarget, whatsapp: this.phoneList.current.number });
      this.$socket.emit('mark_unread', {client: this.phoneTarget, whatsapp: this.phoneList.current.number, isGroup: this.phoneList.current.is_group})
      this.$refs.menu.close()
    },
    numberOptions(e, id) {
      this.phoneTarget = id
      this.$refs.menu.open(e)
    },
    showMessageDate(date) {
      if (date.length === 0) {
        return
      }
      let currentDate = new Date();
      if (date.substr(0, 10) === currentDate.getFullYear() + "-" + (currentDate.getMonth()+1).toString().padStart(2, '0') + "-" +  currentDate.getDate().toString().padStart(2, '0')) {
        return date.substr(11, 5)
      } else {
        let regex = date.substr(0, 10).match(/^\s*([0-9]+)\s*-\s*([0-9]+)\s*-\s*([0-9]+)$/);
        return regex[3] + "/" + regex[2] + "/" + regex[1];
      }
    }
  }
}
</script>

<style scoped>
  .sidebar__chat {
    display: flex;
    padding: 15px 20px;
    cursor: pointer;
    border-bottom: 1px solid #f6f6f6;
  }

  .sidebar__chat:hover {
    background-color: #d3d3d3;
  }

  .sidebar__chat:hover .hide {
    display: inline-block;
  }
  .sidebar__chat.tecnico {
    background-color: #e7fce1;
  }
  .sidebar__chat.selected {
      background-color: #ebebeb;
  }
  .sidebar__chat.not_registered {
    background-color: #ffb4b4;
  }
  .sidebar__chat_info_name {
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: bold;
  }
  .sidebar__chat_info_company_name, .sidebar__chat_info_tecnico_name {
    margin-left: 10px;
    font-size: 13px;
    font-style: italic;
  }
  .sidebar__chat_info_tecnico_name {
    margin-left: 20px;
  }
  .sidebar__chat_info {
    margin-left: 15px;
  }
  .sidebar__last_message {
    font-size: 12px;
  }
  .hide {
    display: none;
  }
  .group {
    font-size: 12px;
    font-style: italic;
    color: #5a5a5a;
  }

</style>