<template>
  <div class="container">
    <div class="user_card">
      <div style="display: flex; justify-content: center;">
        <div class="brand_logo_container">
          <img src="https://plataforma.ptrz.com/imagenes/pegasus_wa.png" class="brand_logo" width="290px" alt="Logo">
        </div>
      </div>
      <div style="display: flex; justify-content: center;" class="form_container">
        <v-form @submit.prevent="login()"
          ref="form"
          lazy-validation
        >
          <div style="color: red; font-weight: bold; margin-bottom: 2px;" v-if="error">
            Usuario o contraseña incorrectos
          </div>
          <div v-else>&nbsp;</div>
          <v-text-field
            v-model="username"
            prepend-icon="mdi-account"
            solo
            dense
            name="username"
            :rules="userRules"
            required
            label="Usuario"
            placeholder="Usuario"
            type="text"
          ></v-text-field>
          <v-text-field
            v-model="password"
            prepend-icon="mdi-key"
            solo
            dense
            name="password"
            :rules="passwordRules"
            required
            label="Contraseña"
            placeholder="Contraseña"
            type="password"
          ></v-text-field>
          <div class="login_container">
            <button type="button" name="button" class="btn login_btn" @click.prevent="login()">
              <v-progress-circular v-if="load"
                indeterminate
                :size="20"
                :width="2"
                color="primary"
              ></v-progress-circular>
              Entrar
            </button>
          </div>
        </v-form>
      </div>
      <div class="condiciones"><a href="/condiciones.html" target="_blank">POLITICA DE PRIVACIDAD</a> </div>
    </div>

  </div>

</template>

<script>
import plataformaService from '@/services/plataforma'
import { mapActions } from 'vuex'
export default {
  name: "login",
  data: () => ({
    username: '',
    password: '',
    error: false,
    load: false,
    userRules: [
      v => !!v || 'Usuario obligatorio'
    ],
    passwordRules: [
      v => !!v || 'Contraseña obligatoria'
    ],
  }),
  methods: {
    ...mapActions(['setToken']),
    async login() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.load = true
      let response = await plataformaService.login({ username: this.username, password: this.password })
      const respuesta = response.data;
      this.load = false
      if (respuesta.codigo === 1) {
        this.error = true
        return
      }
      this.setToken(response.data.token)
      if (!localStorage.getItem('current')) {
        this.$router.push("phoneselect")
      } else {
        this.$router.push("chat")
      }
    }
  }
};
</script>

<style scoped>

  .condiciones {
    text-align: center;
    font-size: 10px;
    margin-top: 14px;
  }

  .condiciones a {
    text-decoration: none;
    color: #5a5a5a;
  }

  .user_card {
    height: 350px;
    width: 370px;
    background: #e1e1e1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
  }
  .brand_logo_container {
    position: absolute;
    height: 100px;
    width: 350px;
    top: 16px;
    text-align: center;
  }
  .brand_logo {
    width: 290px;
  }
  .form_container {
    margin-top: 120px;
  }
  .login_btn {
    width: 100%;
    background: #007a8b !important;
    color: white !important;
    font-weight: bold;
  }

  .login_container {
    padding: 0 1rem;
  }

  >>>.v-icon {
    background: #b7bf10 !important;
    color: white !important;
    border: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
    padding: 7px;
    margin-right: 12px;
  }

  >>> .error--text {
    color: rgba(255, 0, 0, 0.7) !important;
  }

  .btn {
    font-weight: bold;
    color: #212529;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1.1rem;
    line-height: 1.5;
    border-radius: .25rem;
  }

  form {
    width: 250px;
  }
  .container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  >>>.v-input__slot {
    box-shadow: none !important;
    border-radius: 0 !important;
  }
</style>
